import { useEffect, useRef, useState } from "react";
import styles from "./Watch.module.scss";

import LoadView from "../../lib/loading";
import userStorage from "../../lib/storage/user";
import { useNavigate, useParams } from "react-router-dom";
import { fetchEvent } from "../../lib/api";
import { Asset } from "../../components/asset/Asset";
import { ClapprPlayer, TypePlayerView } from "evts-player";


const sleep = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));
const capitalize = (name: string) => {
  if (!name) {
    return "";
  }

  return name.replace(/\w\S*/g, (w) =>
    w.replace(/^\w/, (c) => c.toUpperCase())
  );
};

export default function Watch() {
  const navigate = useNavigate();
  const [chatStatus, setChatStatus] = useState(true);
  const [playerStatus, setPlayerStatus] = useState(true);
  const [event, setEvent] = useState<any | null>(null);
  const [user, setUser] = useState<any>({ loaded: false });
  const [logoClass, setLogoClass] = useState("");
  const [chatClass, setChatClass] = useState("");
  const [playerClass, setPlayerClass] = useState("");
  const [url, setUrl] = useState<string>("");
  const { owner, slug } = useParams();

  const [playerView, setPlayerView] = useState<TypePlayerView>('');
  
  const initPlayerView = () => {
    const timeToStart = (new Date(event.webinar.start_time).getTime() / 1000 - new Date().getTime() / 1000);
    
    const urls = window.location.href.includes("stg-") ? [
      `https://stg-egress.eventials.com/${event.webinar.playback_stream_name}/index.m3u8`,
      `https://stg-vod.eventials.com/${event.webinar.playback_stream_name}/vod.m3u8`,
      `https://ondemand.eventials.com/live/${event.webinar.playback_stream_name}_live.json/master.m3u8`,
      `https://ondemand.eventials.com/vod_drm/${event.webinar.playback_stream_name}.json/master.m3u8`
    ] : [
      `https://egress.eventials.com/${event.webinar.playback_stream_name}/index.m3u8`,
      `https://vod.eventials.com/${event.webinar.playback_stream_name}/vod.m3u8`,
      `https://ondemand.eventials.com/live/${event.webinar.playback_stream_name}_live.json/master.m3u8`,
      `https://ondemand.eventials.com/vod_drm/${event.webinar.playback_stream_name}.json/master.m3u8`
    ];

    const requests = urls.map(url =>
      fetch(url)
        .then(response => {
          if (response.ok) {
            return { url, status: response.status, data: response.json() };
          } else {
            throw new Error(`Request failed for ${url}. Status: ${response.status}`);
          }
        })
        .catch(error => {
          return { url, status: 'Error', error: error.message };
        })
    );

    Promise.all(requests)
      .then(results => {
        const successReq = results.find(result => result.status === 200 || result.status === 304);
        if(successReq){
          if(successReq.url.includes("ondemand.eventials.com/vod_drm/") && timeToStart <= 0){
            setPlayerView("recorded");
          }
          else if (successReq.url.includes("egress") || successReq.url.includes("ondemand.eventials.com/live/")){
            setPlayerView("live");
          }
          else if(successReq.url.includes("vod.eventials.com")){
            setPlayerView("recorded");
          }
          else {
            setPlayerView("upcoming");
          }
        }
        else {
          setPlayerView("upcoming");
        }
      })
      .catch(error => {
        console.error('Error during requests:', error);
      });
  }

  useEffect(() => {
    loadUser();
    if(event) {
      initPlayerView();
    }
  }, [event]);

  function setEmbedUrl() {
    if (event?.webinar?.current_player_backup === "youtube") {
      if (event?.webinar?.embed_youtube) {
        setUrl(event.webinar.embed_youtube)
      }
      return
    }

    if (event?.webinar?.current_player_backup === "twitch") {
      if (event?.webinar?.embed_twitch) {
        setUrl(event.webinar.embed_twitch)
      }
      return
    }

    const storedUser = localStorage.getItem(`@MK-WEBINAR-${event.webinar.slug}`)
    let userEmail = ""

    if (storedUser) {
      userEmail = JSON.parse(storedUser).email
    }

    setUrl(`${event?.webinar.embed_url}/?email=${userEmail || user?.email}&no_login=true`)

  }

  useEffect(() => {
    if (event && user) {

      if (event?.webinar?.player_backup_active && event?.webinar?.player_backup_active?.toLowerCase() === "true") {
        setEmbedUrl()
      }
    }
  }, [event, user])

  useEffect(() => {
    getEvent();
  }, [slug]);

  useEffect(() => {
    if (chatStatus && playerStatus) {
      startIframeAnimations();
    }
  }, [chatStatus, playerStatus]);

  useEffect(() => {
    if (user.loaded) {
      startLogoAnimation();
    }
  }, [user.loaded, event]);


  const loadUser = async () => {
    if (!event) return
    const storedUser = userStorage.load(`${slug}`);
    if (!storedUser) {
      redirectToSubscribe();
      return;
    }
    setUser({ ...storedUser, loaded: true });
  };

  const startLogoAnimation = async () => {
    await sleep(200);
    setLogoClass(styles.logo_middle);
    await sleep(600);
    setLogoClass(styles.logo_end);
  };

  const startIframeAnimations = async () => {
    setPlayerClass("slide-from-left");
    setChatClass("slide-from-right");
  };

  if (!event) {
    return <LoadView />;
  }


  async function getEvent() {
    if (!slug) return

    await fetchEvent(`webinar`, {
      owner,
      slug
    }).then((res) => {
      if(res){
        setEvent(res)
      }
    })
  }

  const redirectToSubscribe = () => {
    userStorage.clear();
    navigate(`/${owner}/${slug}`);
  };

  const onLoadedChat = () => {
    setChatStatus(true);
  };

  const onLoadedPlayer = (event: any) => {
    setPlayerStatus(true);
  };

  return (
    <>
      {!user.loaded ? (
        <LoadView />
      ) : (
        <div className={styles.container}>
          <div className={styles.header}>
            <div />
            <div className={styles.user_bar}>
              <span id="user-name" style={{ marginRight: "16px", color: 'black', fontWeight: 700 }}>
                {user?.name ? capitalize(user?.name) : user?.username}
              </span>
              <button className={styles.logoff} onClick={redirectToSubscribe}>
                Sair
              </button>
            </div>
          </div>

          {event && (
            <>
              <div style={{ display: "flex", width: "90%" }} className={`${styles.playerContainer} ${styles.live}`} >

                { event?.webinar?.player_backup_active && event?.webinar?.player_backup_active?.toLowerCase() === "true" ? (
                  <iframe
                    className={`${styles.player} ${playerClass}`}
                    // ref={iframePlayer}
                    allowFullScreen
                    allow="autoplay"
                    frameBorder={0}
                    onLoad={onLoadedPlayer}
                    title="embed"
                    src={url}
                  // src="https://staging.eventials.com/player-embed/h2ope4cirt5jxh8b"
                  />
                ) : (
                  <div className={`${styles.player} ${playerClass}`}>
                    <ClapprPlayer
                      channel={event.webinar.owner_username} 
                      email={user.email} 
                      playerView={playerView}
                      setPlayerView={setPlayerView}
                      talk={{
                        ...event.webinar, 
                        time_to_start: new Date(event.webinar.start_time).getTime() / 1000 - new Date().getTime() / 1000,
                      }}
                    />
                  </div>
                )
              }
                <iframe
                  // ref={iframeChat}
                  className={`${styles.chat} ${chatClass}`}
                  allowFullScreen
                  allow="autoplay"
                  frameBorder={0}
                  onLoad={onLoadedPlayer}
                  title="embed"
                  src={`${event?.webinar.chat_url}/?username=${user?.name}`}
                />
              </div>

              <div className={styles.assets_container}>
                {
                  event.webinar.assets &&
                  event.webinar.assets.map((asset: { url: string, name: string }) =>
                    <Asset url={asset.url} name={asset.name} />)
                }
              </div>

            </>
          )}
        </div>
      )}
    </>
  );
}
